import $ from 'jquery';
import Swiper from 'swiper';
import {
  TimelineMax,
  Power3
} from 'gsap';
import {httpCall} from "../httpcall";
// import 'swiper/swiper-bundle.css';



export const gamePage = () => {
  var swiper = new Swiper('.game-swiper', {
    slidesPerView: 6,
    spaceBetween: 30,
    freeMode: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      100: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 50,
      },
      1920: {
        slidesPerView: 6,
        spaceBetween: 50,
      },
    }
  });

  let earnedBadges = new TimelineMax({
    paused: true
  });

  /*earnedBadges.fromTo('.badges li .item', .3, {
    top: '45px',
    opacity: '0',
    ease: Power3.easeInOut
  }, {
    opacity: '1',
    top: '0',
    stagger: '.2',
    ease: Power3.easeInOut
  })*/

  earnedBadges.fromTo('.game-page .levels .horizontal-line', 2, {
    opacity: '0',
    width: '0',
    ease: Power3.easeInOut
  }, {
    opacity: '1',
    width: '100%',
    stagger: '.2',
    ease: Power3.easeInOut
  }, '-=.4')

  earnedBadges.fromTo('.game-page .levels .level-item', .5, {
    opacity: '0',
    ease: Power3.easeInOut
  }, {
    opacity: '1',
    stagger: '.2',
    ease: Power3.easeInOut
  }, '-=.7')



  earnedBadges.play()
  const bindCards=(data)=>{
    //const data=[{weekId:'123',stars:2},{weekId:'456',stars:0}];
    data.forEach(function (value,index) {
      if(value){
        if(value._id) {
          jQuery('#card' + index + ' a')[0].href = 'video_page.html?challengeId=' + value._id;
          jQuery('#card' + index + ' a').removeClass('locked');
          jQuery('#card' + index).removeClass('locked');
          jQuery('#card' + index).addClass('show-card');
          if(data.length-1===index){
            jQuery('#card' + index).addClass('current-card');
            jQuery('#card' + index+' .title-week').html('Watch this week\'s video');
          }
        }
        if(value.completedVideoNo){
          jQuery('#card'+index+' .stars-rating').children().each(function(index1)
          { if(value.completedVideoNo>index1)
            jQuery(this).addClass('checked')
          })
        }
      }
    })
  };
  const fetchChallenges=()=>{
    httpCall({url:'game/challenges',method:'GET',headers:{
        Authorization:localStorage.getItem('token')
      }}).then((res)=>{
      bindCards(res.data.activeChallenges);
    }).catch(()=>{
      console.log('asdsad')

    })
  }
  fetchChallenges();
}