import {httpCall} from "../httpcall";
function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export const forgetPage = () => {

  jQuery('#forget').on('click', function () {
    const email = jQuery('#email').val();

    if (validateEmail(email)) {
      httpCall({
        url: 'auth/recover',
        headers: {'Content-Type': 'application/json'},
        data: {
          email,
        },
        method: 'POST'
      }).then(()=>{
        jQuery.toast({
          text: "Check your email to reset your password",
          loader:false,
          showHideTransition: 'fade', // fade, slide or plain
          allowToastClose: true, // Boolean value true or false

          position: 'top-left',
        })
      })
    }
    else {
      jQuery.toast({
        text: "Enter a valid email",
        loader:false,
        showHideTransition: 'fade', // fade, slide or plain
        allowToastClose: true, // Boolean value true or false


        position: 'top-left',
      })
    }
  })
};