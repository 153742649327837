import $ from 'jquery';
import {
    TimelineMax,
    Power3
} from 'gsap';

(function nav() {

    
    let menuBtn = document.getElementById('menu-btn');
    let navbar = document.getElementById('navbar');
    // let list = $(".nav-menu--active > .nav-menu-list--item");
        let navTimeLine = new TimelineMax({
            paused: true
        });
        navTimeLine.fromTo('.nav-menu-overlay',.5, {
            display: 'none',
            width: '0',
            ease: Power3.easeInOut
        },{
            display: 'flex',
            width: '100vw',
         }
        );
        navTimeLine.fromTo('.nav-menu-overlay ul li a', .5, {
                    opacity: '0',
                    left: '-9rem',
                    ease: Power3.easeInOut
                }, {
                    opacity: '1',
                    left: '0rem',
                    stagger:'.2',
                    ease: Power3.easeInOut
            })



        navTimeLine.reverse()
        window.addEventListener('resize',function(){
            window.innerWidth <= 1024 ? navbar.classList.add('nav-menu--active') : navbar.classList.remove('nav-menu--active')
        })
        window.innerWidth <= 1024 ? navbar.classList.add('nav-menu--active') : navbar.classList.remove('nav-menu--active')

        menuBtn.addEventListener('click', function () {
            navTimeLine.reversed(!navTimeLine.reversed())
            $('html').toggleClass('overflow-hide');
        })
    })()
