import $ from 'jquery';
import './../../../node_modules/bootstrap/dist/js/bootstrap.js';
import { TimelineMax,Power3 } from 'gsap';
import ConfettiGenerator from "confetti-js";
import Cropper from 'cropperjs';
import {httpCall} from '../httpcall';
import axios from 'axios';
import {countries} from '../config';
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const registerPage = () => {
    let loading=false;
  let picture;
    //hide skip button imm
    $('#skipBtn').fadeOut()
    var userObject={};
    var favorite=[];
    let schools;
    //handle dropdownlist
    $(function () {

        $('.jc-select > .caption').on('click', function () {
            $(this).parent().toggleClass('open');
        });

        $('.jc-select > .list > .item').on('click', function () {
            $('.jc-select.ageList > .list > .item').removeClass('selected');
            $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').html('');
            $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').append($(this).text()+'<i class="fas fa-caret-down caret-down pointer"></i>');
            $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').addClass('boldClass');

        });
        $(document).on('click', function (evt) {
            if ($(evt.target).closest(".jc-select > .caption").length === 0) {
                $('.jc-select').removeClass('open');
            }
        });
        const fillSchoolList=()=>{
            httpCall({
                url:'admin/schools',
                method:'GET',
            }).then((res)=>{
                const school=res.data.schools;
                schools=res.data.schools;
                school.forEach((item)=>{
                    const div=document.createElement('div')
                    div.innerText=item.name;
                    div.setAttribute('idValue',item.id);
                    div.classList='item';
                    div.addEventListener('click',function () {
                        $('.jc-select.schoolName > .list > .item').removeClass('selected');
                        $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').html('');
                        $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').append($(this).text()+'<i class="fas fa-caret-down caret-down pointer"></i>');
                        $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').addClass('boldClass');
                    });
                    jQuery('.schoolName .list').append(div)

                })
            });


        }
        fillSchoolList();
        const fillNationalityList=()=>{
            countries.forEach((item)=>{
            const div=document.createElement('div')
                div.innerText=item.name;
            //div.setAttribute('idValue',item.id);
            div.classList='item';
            div.addEventListener('click',function () {
                $('.jc-select.nationalName > .list > .item').removeClass('selected');
                $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').html('');
                $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').append($(this).text()+'<i class="fas fa-caret-down caret-down pointer"></i>');
                $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').addClass('boldClass');
                /*jQuery('.jc-select > .list > .item').removeClass('selected');
                jQuery(jQuery(this)).addClass('selected')*/
            });
                jQuery('.nationalName .list').append(div)

            })

        };
        fillNationalityList();
        const validateBackendData=()=>{
            const school=schools.filter(item=>item.id===userObject.schoolNameValue)[0];
            const data={
                firstName: userObject.firstName,
                lastName: userObject.lastName,
                school,
                age: userObject.userAgeValue,
                userType: 1,
                email: userObject.userEmail,
                password: userObject.userPassword,
                phone: userObject.phoneNumber,
                nationality: userObject.userNationalityValue,
            };
            return httpCall({headers:{'Content-Type':'application/json'},
                url:'auth/register/validate-first-phase',
                data:data,
                method:'POST',
            }).then(()=>{

                return'';
            }).catch((res)=>{

                if(res.response){
                    return res.response.data.message;
                }
                return 'Error in validation'
            });
        }
const registerUser=()=>{
    const school=schools.filter(item=>item.id===userObject.schoolNameValue)[0];
  const data={
    firstName: userObject.firstName,
    lastName: userObject.lastName,
    school,
    age: userObject.userAgeValue,
    userType: 1,
    email: userObject.userEmail,
    password: userObject.userPassword,
    nationality: userObject.userNationalityValue,
    phone: userObject.phoneNumber,
    favSports: favorite,
  };
  if(picture){
    data.profile={
      imgExt: "png",
      contentType: "image/png",
    }
  }
  if(!loading){
      loading=true;
      return httpCall({headers:{'Content-Type':'application/json'},
          url:'auth/register',
          data:data,
          method:'POST',
      }).then((res)=>{
          loading=false;
          localStorage.setItem('token',res.data.token);
          if(res.data.user.profile)
              localStorage.setItem('userImage',res.data.user.profile.s3_original);
          if(res.data.profileSignedURL){
              axios.put(res.data.profileSignedURL,picture.blob,{
                  headers: {
                      'Content-Type': 'image/png',
                  },
              }).then(()=>{
                  $('.pop-up--normal').fadeIn()
                  setTimeout(function(){
                      window.location.href = "./game.html";
                  },3000)

              })
          }
          else {
              $('.pop-up--normal').fadeIn()
              setTimeout(function(){
                  window.location.href = "./game.html";
              },3000)
          }
      }).catch(()=>{
          loading=false;
          throw 'Check the connection'
      })
  }


};



    // registrationPage Animation
    ////////////////////////////////////////////////////////////////////////
    //STEP TWO ANIMATION
    let registeTl = new TimelineMax({
        paused: true
    });
    registeTl.to('#play_1',{display:'block',opacity:1})
    registeTl.fromTo('#userDataStep ', {display: 'block',opacity: '1',ease: Power3.easeInOut}, {duration: 0.3, display: 'none',opacity: 0,ease: Power3.easeInOut,})
    .to('#tl_step_one ', { className: "+=circle is-complete"})
    .to('.timeline-progress', {width: '50%'}, '-=0.5')
    .to('#tl_step_two ', {className: "+=circle is-current"})
    .to('.timeline-progress', {width: '50%'}, '-=0.5')
    .to('#tl_step_two ', {className: "+=circle is-current"})
    .to('#play_1',{left:'-10rem',display:'none',opacity:0},'-=1')
    .fromTo('#favSportsStep ', {display: 'none',opacity: '0',ease: Power3.easeInOut}, {duration: 0.3,display: 'block',opacity: 1,ease: Power3.easeInOut,className: "+=step_two is-shown"}, '-=1.6')
    .to('#play_2',{display:'block',left:'-6rem',opacity:1},'-=.5')



    ////////////////////////////////*********************////////////////////////////////////////
    //STEP TWO ANIMATION
    let registeTl2 = new TimelineMax({paused: true});
    registeTl2.to('#favSportsStep ', {duration: 0.3,display: 'none',opacity: 0,ease: Power3.easeInOut, }).to('#tl_step_two', {className: "+=circle is-complete"})
        .to('.timeline-progress', {width: '95%'}, '-=0.5')
        .to('#tl_step_three', {className: "+=circle is-current"})
        .to('#play_2',{left:'-10rem', display:'none',opacity:0},'-=1')
        .fromTo('#uploadPhotoStep ', {display: 'none',opacity: '0', ease: Power3.easeInOut}, {duration: 0.3,display: 'block',opacity: 1,ease: Power3.easeInOut, className: "+=step_three is-shown"}, '-=1')
        .to('#play_3',{display:'block',left:'2rem',opacity:1},'-=.5')
    ////////////////////////////////////////////////////////////////////////





    // used in center div in homescreen  -  strips
    $('#nextBtn').on('click', async function () {
        let getIDS = $('.jc-select > .list > .item').parent().parent().children('.caption').attr('id');


        // getting values from DROPDOWN MENU
        let schoolNameValue = jQuery('#schoolName').text().trim();
        let userAgeValue = jQuery('#userAge').text().trim();
        let userNationalityValue = jQuery('#userNationality').text().trim();
        // END getting values from DROPDOWN MENU
        
        //****************************************************///////////////////////

        // getting values from normal inputs
        let firstName = document.getElementById('firstName').value;
        let lastName = document.getElementById('lastName').value;
        let phoneNumber = document.getElementById('phone').value;
        let userEmail = document.getElementById('userEmail').value;
        let userPassword = document.getElementById('userPassword').value;

        jQuery('#firstName').css('border-color','transparent');
        jQuery('#lastName').css('border-color','transparent');
        jQuery('#userPassword').css('border-color','transparent');
        jQuery('#schoolName').css('border-color','transparent');
        jQuery('#userNationality').css('border-color','transparent');
        jQuery('#phone').css('border-color','transparent');
        jQuery('#userEmail').css('border-color','transparent');
        jQuery('#userAge').css('border-color','transparent');
    if(!firstName||!lastName||schoolNameValue==="School Name"||userNationalityValue=== "Nationality"||
      userAgeValue==='Age'||!userEmail||!userPassword){
        if(!firstName) jQuery('#firstName').css('border-color','#D92027')
        if(!lastName) jQuery('#lastName').css('border-color','#D92027')
        if(!userPassword) jQuery('#userPassword').css('border-color','#D92027')
        if(schoolNameValue==='School Name') jQuery('#schoolName').css('border-color','#D92027')
        if(userNationalityValue==='Nationality') jQuery('#userNationality').css('border-color','#D92027')
        if(!userEmail) jQuery('#userEmail').css('border-color','#D92027')
        if(userAgeValue==='Age') jQuery('#userAge').css('border-color','#D92027')
        jQuery.toast({
            text: "Invalid inputs !",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
        });
            return;
    }
    if(phoneNumber&&!new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g).test(phoneNumber)){
        jQuery('#phone').css('border-color','#D92027');
        jQuery.toast({
            text: "Phone number is invalid!",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
        });
        return;
    }
    if(!validateEmail(userEmail)){
        $('#userEmail').css('border-color','#D92027')
        jQuery.toast({
            text: "Email is invalid!",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
        });
        return;
    }
        if(!jQuery('#terms')[0].checked){
            jQuery('#terms').css('box-shadow','#D92027 0 0 3px')
            jQuery('#terms').on('change',function () {
                jQuery('#terms').css('box-shadow','none')
            })
            jQuery.toast({
                text: "You should accept terms",
                loader:false,
                showHideTransition: 'fade', // fade, slide or plain
                allowToastClose: true, // Boolean value true or false


                position: 'top-left',
            });
            return;
        }
    /*setTimeout(()=>{

    jQuery('.changed-container').css('min-height','500px')
    },500);*/
    userObject={
        firstName,
        lastName,
        userPassword,
        schoolNameValue:jQuery('.schoolList .selected')[0].getAttribute('idValue'),
        userNationalityValue,//jQuery('.nationalName .selected')[0].getAttribute('idValue'),
        phoneNumber,
        userEmail,
        userAgeValue,
    }
        const invalid=await validateBackendData();
    if(invalid){
        return jQuery.toast({
            text: invalid,
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
        });
    }
        // play animation
        registeTl.play();
        $('#skipBtn').css({display:'flex'});
        $('#backBtn').removeClass('disabled')

        //get value from user to apper in website
        let userNameValue = $("#firstName").val().toUpperCase();
        let res = userNameValue.substring(0, 18);

        $('#userNameTitle').html(`<span class='red-word thin-font'>hey,</span><span>${res} </span>`)
        if ($('#favSportsStep').hasClass('is-shown')) {
            validateCheckBoxs();
        }

        function validateCheckBoxs() {
            const form = document.getElementById('checkBoxform');
            const formData = new FormData(form);
            const formInputsChecked = formData.has('checkbox-group');
            favorite=[];
            if (formInputsChecked) {
                jQuery.each(jQuery("input[name='checkbox-group']:checked"), function(){

                    favorite.push(jQuery(this).val());
                });

                registeTl2.play()
            } else {
                jQuery('#skipBtn').click();
                // console.log(' Not Comeplete ');
            }
            jQuery('#skipBtn').fadeOut();
        }
         nextPage();
    });

    $('#backBtn').on('click', function () {})
    $('#skipBtn').on('click', function () {
        jQuery('#skipBtn').fadeOut();
        registeTl2.play()
        if ($('#uploadPhotoStep').hasClass('is-shown')) {
            registerUser().then(()=>{
                let popTl = new TimelineMax({
                    paused: true
                });
                popTl.fromTo('.pop-up--normal',{
                    display:'none',
                    opacity:'0'
                },{
                    display:'block',
                    opacity:'1',
                })
                popTl.fromTo('#complete_badge',{
                    display:'none',
                    opacity:'0'
                },{
                    display:'block',
                    opacity:'1'
                },'+=.9')
                popTl.play()
                popTl.eventCallback("onComplete", function(){

                })
            }).catch(()=>{})

        }
        /*$('.pop-up--normal').fadeIn()
        setTimeout(function(){
            window.location.href = "./game.html";
        },2000)*/
    })




    // Start --  convetti popup after registration complete
    var confettiElement = document.getElementById('confetti-badge');
    var confettiSettings = {"target":"confetti-badge",
      "max":"80","size":"6",
      "animate":true,
      "props":["circle"],
      "colors":[[165,104,246],[230,61,135],[0,199,228],[253,214,126]],
      "clock":"25",
      "rotate":false,"width":"2500",
      "height":"2500",
      "start_from_edge":false,"respawn":true};
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    // END -- Start convetti popup after registration complete

    // To Show convettti
        function nextPage () {
            if ($('#uploadPhotoStep').hasClass('is-shown')) {
                console.log('ASADSADSADSAD')
                registerUser().then(()=>{
                    let popTl = new TimelineMax({
                        paused: true
                    });
                    popTl.fromTo('.pop-up--normal',{
                        display:'none',
                        opacity:'0'
                    },{
                        display:'block',
                        opacity:'1',
                    })
                    popTl.fromTo('#complete_badge',{
                        display:'none',
                        opacity:'0'
                    },{
                        display:'block',
                        opacity:'1'
                    },'+=.9')
                    popTl.play()
                    popTl.eventCallback("onComplete", function(){
                    }, ["param1","param2"]);
                }).catch(()=>{});

            }

        }


   
//////////
// - Register drag and drop function 
// - using Pure Javascript
// - and crop Image function work with Cropper.js lib 
//////////
var dropDone = false;
const change_photo = document.getElementById('changePhotoInput');

   document.querySelectorAll('.drop-zone__input').forEach((inputElement) => {


    let cropper,canvas,uploaded_image;
    let deffaultUser = './imgs/user.png';
    const popUp = document.getElementById('cropped-image--modal');
    uploaded_image = document.getElementById('uploaded_image')
    const drop_text = document.getElementById('dropText');
    const view_image = document.getElementById('view_image');
    const croppedImage = document.getElementById('croppedImage');
    const cropBtn = document.getElementById('crop');
    const saveBtn = document.getElementById('saveImage');
    // const finalImage = document.getElementById('finalImage');


        //inputElement equal input tag that receive the file
       const dropZoneElement = inputElement.closest(".drop-zone--container");
    console.log(dropZoneElement);
        // add class when drag on box
        dropZoneElement.addEventListener('dragover',(e) =>{
            e.preventDefault()
            dropZoneElement.classList.add('drop-zone--over')
        });
  

        // handler event when leave box
        ['dragleave','dragend'].forEach(type => {
            dropZoneElement.addEventListener(type, e => {
                dropZoneElement.classList.remove('drop-zone--over')
            })
        })



        // handle drop event 
        // 1 -  show popup
        // 2 -  crop Image
        // 3 -  show image thumb
        // 4 -  upload image 
        dropZoneElement.addEventListener('drop', e => {
            e.preventDefault();
            finalImage.src = deffaultUser;
            processImage(e)
            
        })
        const processImage = (e) =>{
            let options = {
                aspectRatio: 1,
                viewMode:13,
                preview:croppedImage,
                zoomable:true,
                zoomOnTouch:true,
                crop(event){
                    canvas = this.cropper.getCroppedCanvas({
                        width:200,
                        height:200,
                        imageSmoothingEnabled: true,
                        imageSmoothingQuality: 'high',
                    });
                    croppedImage.src = canvas.toDataURL('image/png')
                    croppedImage.style.width = '200px';
                    croppedImage.style.height = '200px';
                }
            }
            
                let imageToProcess = e.dataTransfer.files[0];
          
                let newImage  = new Image();
                newImage.src = URL.createObjectURL(imageToProcess);
                newImage.id = 'userImage';
                view_image.appendChild(newImage)

                $("#cropped-image--modal").modal("show");

                $('#cropped-image--modal').on('shown.bs.modal',function(){
                    cropper = new Cropper(newImage,options)
                }).on('hidden.bs.modal', function(){
                    $( ".cropper-container" ).remove();
                    $( "#view_image img" ).remove();
                    finalImage.src = deffaultUser;
                });

                cropBtn.addEventListener('click', () =>{
                    canvas = cropper.getCroppedCanvas({
                        width:200,
                        height:200
                    })
                    cropper.crop();
                    canvas.toBlob(function(blob){
                        finalImage.src = URL.createObjectURL(blob);
                        finalImage.id = 'finalImage';
                    })
                    const imageData = cropper.getImageData();
                    const canvasData = cropper.getCanvasData();
                    console.log(imageData);
                    console.log(canvasData);

                })
                saveBtn.addEventListener('click',function(){
                    dropDone = true;
                    canvas.toBlob(function(blob ){
                        uploaded_image.src = URL.createObjectURL(blob);
                        console.log(URL.createObjectURL(blob));
                        picture.blob=blob;

                    });

                    $("#cropped-image--modal").modal("hide");
                    drop_text.style.display = 'none';
                    uploaded_image.classList.add('stopClick');
                    uploaded_image.style.display = 'block';
                   
                })
       
        }
       



        const processImageForClick = (e) =>{
            let options = {
                aspectRatio: 1,
                viewMode:13,
                preview:croppedImage,
                zoomable:true,
                zoomOnTouch:true,
                crop(event){
                    canvas = this.cropper.getCroppedCanvas({
                        width:200,
                        height:200,
                        imageSmoothingEnabled: true,
                        imageSmoothingQuality: 'high',
                    });
                    croppedImage.src = canvas.toDataURL('image/png')
                    croppedImage.style.width = '200px';
                    croppedImage.style.height = '200px';
                }
            }
            
                let imageToProcess = e.target.files[0];
          
                let newImage  = new Image();
                newImage.src = URL.createObjectURL(imageToProcess);
                newImage.id = 'userImage';
                view_image.appendChild(newImage)

                $("#cropped-image--modal").modal("show");

                $('#cropped-image--modal').on('shown.bs.modal',function(){
                    cropper = new Cropper(newImage,options)
                }).on('hidden.bs.modal', function(){
                    $( ".cropper-container" ).remove();
                    $( "#view_image img" ).remove();
                    finalImage.src = deffaultUser;
                });

                cropBtn.addEventListener('click', () =>{
                    canvas = cropper.getCroppedCanvas({
                        width:200,
                        height:200
                    })
                    cropper.crop();
                    canvas.toBlob(function(blob){
                        finalImage.src = URL.createObjectURL(blob);
                        finalImage.id = 'finalImage';
                    })
                    const imageData = cropper.getImageData();
                    const canvasData = cropper.getCanvasData();
                    console.log(imageData);
                    console.log(canvasData);
                    
                })
                saveBtn.addEventListener('click',function(){
                    dropDone = true;
                    canvas.toBlob(function(blob ){
                        uploaded_image.src = URL.createObjectURL(blob);
                        console.log(URL.createObjectURL(blob));
                      picture={blob};
                    });

                    $("#cropped-image--modal").modal("hide");
                    drop_text.style.display = 'none';
                    uploaded_image.classList.add('stopClick');
                    uploaded_image.style.display = 'block';
                   
                })
       
        }
                change_photo.addEventListener("change", function(e){
                    console.log(e.target.files[0]);
                    processImageForClick(e)
                });
   
    })

 


});

}