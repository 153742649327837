import { httpCall } from "../httpcall";
import { BADGES } from "../config";

export const profilePage = () => {
  const userImage = localStorage.getItem("userImage");
  if (userImage) {
    jQuery("#userImage2")[0].src = userImage;
  }
  const getProfile = () => {
    httpCall({
      url: "users/my-profile",
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token")
      }
    }).then(res => {
      jQuery("#name").html(res.data.profile.name);
      jQuery("#username").html(res.data.profile.name);
      jQuery("#score").html(res.data.profile.xp);
      jQuery("#age").html(res.data.profile.age);
      jQuery("#xp").html(res.data.profile.xp);
      jQuery("#schoolName").html(res.data.profile.school.name);
      jQuery("#nationality").html(res.data.profile.nationality);
      const sports=res.data.profile.favSports.length>0?res.data.profile.favSports.join(", "):'No Sports';
      if(res.data.profile.favSports.length===0){
        jQuery("#sports").css('opacity',.65);
        jQuery("#sports").css('font-style','italic');
      }
      jQuery("#sports").html(sports);
      let counter = 0;
      res.data.userBoards.userRankBoard.forEach((item, index) => {
        const showCounter =
          index === 0 ||
          res.data.userBoards.userRankBoard[index - 1].xp > item.xp;
        //console.log(item,showCounter);
        if (showCounter) {
          counter++;
        }
        jQuery("#list1").append(`<li class="players mb-1">
<span class="counter">${showCounter ? counter : ""}</span>
                                            <img class="playerImg" src="${item.profile?item.profile.s3_original:'./imgs/test.png'}" alt="">
                                            <div class="data pl-2">
                                                <span title="${item.name || "N/A"}" class="playerName schoolMax">${item.name ||
                                                  "N/A"}</span>
                                                <span class="schoolName"><span title="${item.school.name}" class="schoolMax">${item.school.name}</span>, Age ${item.age ||
          "N/A"}</span>
                                            </div>
                                            <span class="exp"><img src="./imgs/coin.png" /> 
                                        ${item.xp ||
                                          (item.xp === 0 && "0") ||
                                          "N/A"} XP
                                         </span>
            
                                        </li>`);
      });
      counter = 0;
      res.data.userBoards.userSchoolBoard.forEach((item, index) => {
        const showCounter =
          index === 0 ||
          res.data.userBoards.userSchoolBoard[index - 1].xp > item.xp;
        //console.log(item,showCounter);
        if (showCounter) {
          counter++;
        }
        jQuery("#list2").append(`<li class="players mb-1">
<span class="counter">${showCounter ? counter : ""}</span>
                                            <img class="playerImg" src="${item.profile?item.profile.s3_original:'./imgs/test.png'}" alt="">
                                            <div class="data pl-2">
                                                <span title="${item.name || "N/A"}" class="playerName schoolMax">${item.name ||
                                                  "N/A"}</span>
                                                <span class="schoolName"><span title="${item.school.name}" class="schoolMax">${item.school.name}</span>, Age ${item.age ||
          "N/A"}</span>
                                            </div>
                                            <span class="exp"><img src="./imgs/coin.png" /> 
                                        ${item.xp ||
                                          (item.xp === 0 && "0") ||
                                          "N/A"} XP
                                         </span>
            
                                        </li>`);
      });
      counter = 0;
      res.data.userBoards.userAgeBoard.forEach((item, index) => {
        const showCounter =
          index === 0 ||
          res.data.userBoards.userAgeBoard[index - 1].xp > item.xp;
        //console.log(item,showCounter);
        if (showCounter) {
          counter++;
        }
        jQuery("#list3").append(`<li class="players mb-1">
<span class="counter">${showCounter ? counter : ""}</span>
                                            <img class="playerImg" src="${item.profile?item.profile.s3_original:'./imgs/test.png'}" alt="">
                                            <div class="data pl-2">
                                                <span title="${item.name || "N/A"}" class="playerName schoolMax">${item.name ||
                                                  "N/A"}</span>
                                                <span class="schoolName"><span title="${item.school.name}" class="schoolMax">${item.school.name}</span>, Age ${item.age ||
          "N/A"}</span>
                                            </div>
                                            <span class="exp"><img src="./imgs/coin.png" /> 
                                        ${item.xp ||
                                          (item.xp === 0 && "0") ||
                                          "N/A"} XP
                                         </span>
            
                                        </li>`);
      });
      Object.keys(res.data.profile.rewards[0].badges).forEach(item => {
        if (res.data.profile.rewards[0].badges[item] > 0) {
          jQuery("#noBadges").addClass("d-none");
          const badge = BADGES[item];
          jQuery("#badges").append(`<li><a id="${item}" data-toggle="modal" data-target="#exampleModal" href="#">
                                        <span class="badge badge-light">${res.data.profile.rewards[0].badges[item]}</span>
                                            <img src="${badge.img}" alt=""></a></li>`);
          jQuery(`#${item}`).on("click", function(e) {
            jQuery("#exampleModalLabel").html(badge.title);
            jQuery(".modal-body").html(
              `<img width="150px" src="${badge.img}"/><h6 class="pt-3 ">${badge.details}</h6>`
            );
          });
        }
      });
    });
  };
  getProfile();
};
