import lax from 'lax.js';

export const initLaxAnimation = () => {
    window.onload = function() {
        lax.setup() // init
      
        const updateLax = () => {
            lax.update(window.scrollY)
            window.requestAnimationFrame(updateLax)
        }
      
        window.requestAnimationFrame(updateLax)
      }
      console.log('init laax');
}


