import {httpCall} from "../httpcall";

export const leaderboardPage=()=>{
  const getLeaderboard=()=>{
    httpCall({
       url:'users/leaderboards',
      method:'GET',
      headers:{
        Authorization: localStorage.getItem("token")
      }
      }).then((res)=>{
        console.log(res)
      let counter=0;
      res.data.leaderboards.systemKidsBoard.forEach((item, index) => {
        const showCounter =
          index === 0 ||
          res.data.leaderboards.systemKidsBoard[index - 1].xp > item.xp;
        //console.log(item,showCounter);
        if (showCounter) {
          counter++;
        }
        jQuery("#systemKids").append(`<li class="players mb-1">
<span class="counter">${showCounter ? counter : ""}</span>
                                            <img class="playerImg" src="${item.profile?item.profile.s3_original:'./imgs/test.png'}" alt="">
                                            <div class="data pl-2">
                                                <span class="playerName schoolMax" title="${item.name ||
        "N/A"}" style="max-width: 200px">${item.name ||
        "N/A"}</span>
                                                <span class="schoolName"><span class="schoolMax" title="${
          item.school.name
        }">${
          item.school.name
        }</span>, Age ${item.age ||
        "N/A"}</span>
                                            </div>
                                            <span class="exp"><img src="./imgs/coin.png" /> 
                                        ${item.xp ||
        (item.xp === 0 && "0") ||
        "N/A"} XP
                                         </span>
            
                                        </li>`);
      });
      counter=0;
      res.data.leaderboards.userSchoolBoard.forEach((item, index) => {
        const showCounter =
          index === 0 ||
          res.data.leaderboards.userSchoolBoard[index - 1].xp > item.xp;
        //console.log(item,showCounter);
        if (showCounter) {
          counter++;
        }
        jQuery("#mySchool").append(`<li class="players mb-1">
<span class="counter">${showCounter ? counter : ""}</span>
                                            <img class="playerImg" src="${item.profile?item.profile.s3_original:'./imgs/test.png'}" alt="">
                                            <div class="data pl-2">
                                                <span class="playerName"><span title="${item.name || "N/A"}" style="max-width: 180px" class="schoolMax">${item.name ||
        "N/A"}</span></span>
                                                <span class="schoolName"><span title="${item.school.name}" class="schoolMax">${item.school.name}</span>, Age ${item.age ||
        "N/A"}</span>
                                            </div>
                                            <span class="exp"><img src="./imgs/coin.png" /> 
                                        ${item.xp ||
        (item.xp === 0 && "0") ||
        "N/A"} XP
                                         </span>
            
                                        </li>`);
      });
      counter=0;
      res.data.leaderboards.allSchoolsBoard.forEach((item, index) => {
        const showCounter =
          index === 0 ||
          res.data.leaderboards.allSchoolsBoard[index - 1].totalXP > item.totalXP;
        //console.log(item,showCounter);
        if (showCounter) {
          counter++;
        }
        jQuery("#allSchools").append(`<li class="players mb-2">
<span class="counter">${showCounter ? counter : ""}</span>
                                          
                                            <div class="data pl-2">
                                                <span class="playerName schoolMax" title="${item._id || "N/A"}"
                                                 style="max-width: 200px">
${item._id || "N/A"}</span>
                                                
                                            </div>
                                            <span class="exp"><img src="./imgs/coin.png" /> 
                                        ${item.totalXP ||
        (item.totalXP === 0 && "0") ||
        "N/A"} XP
                                         </span>
            
                                        </li>`);
      });
    })
  }
  getLeaderboard();
};