import {httpCall} from "../httpcall";
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const loginPage = () => {
    const login=()=>{
        const email=jQuery('#email').val();
        const password=jQuery('#password').val();
        if(validateEmail(email)&&password){
            try{
                httpCall({url:'auth/login',
                    headers:{'Content-Type':'application/json'},
                    data:{
                        email: email,
                        password: password,
                    },
                    method:'POST'
                }).then((res)=>{
                    localStorage.setItem('token',res.data.token);
                    localStorage.setItem('userImage',(res.data.user.profile&&res.data.user.profile.s3_original)||'');
                    window.location.href='/game.html'
                }).catch((e)=>{
                    jQuery.toast({
                        text: "Email or password is wrong "+e,
                        loader:false,
                        showHideTransition: 'fade', // fade, slide or plain
                        allowToastClose: true, // Boolean value true or false


                        position: 'top-left',
                    })
                })
            }catch (e) {

            }

        }
        else {
            jQuery.toast({
                text: "Email and Password are required",
                loader:false,
                showHideTransition: 'fade', // fade, slide or plain
                allowToastClose: true, // Boolean value true or false


                position: 'top-left',
            })
        }

    }
    jQuery(document).on('keypress',function(e) {
        if(e.which == 13) {
            login()
        }
    });
    jQuery('#loginBtn').on('click',function () {
        login();
    })
}

