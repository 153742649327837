import $ from 'jquery';
import {initLaxAnimation} from './../animation/lax_ani';





export const homePage = () => {
    initLaxAnimation()

}

