import $ from 'jquery';
//  Import LIBS
import 'popper.js';
import 'bootstrap';
//  END Import LIBS

// Import Component
import './animation/navbar';
// Import Component
import Cropper from 'cropperjs';

global.jQuery = require("jquery");

setTimeout(()=>{
  require('../lib/jquery.toast')
},100)
//  Import PAGES
import {loginPage} from './pages/login_page.js';
import {homePage} from './pages/home_page.js';
import {registerPage} from './pages/register_page.js';
import {gamePage} from './pages/game_page';
import {videoPage} from './pages/video_platform';
import {profilePage} from './pages/profile';
import {leaderboardPage} from './pages/leaderboard';
import {forgetPage} from './pages/forget';
import {resetPage} from './pages/reset';
import {httpCall} from "./httpcall";
import axios from "axios";





// This function to run specific function for every page
const runPages = () => {
    document.body.classList.contains('login-page') ? loginPage() : null
    document.body.classList.contains('index-Page') ? homePage() : null
    document.body.classList.contains('register-page') ? registerPage() : null
    document.body.classList.contains('game-page') ? gamePage() : null
    document.body.classList.contains('video-page') ? videoPage() : null
    document.body.classList.contains('userProfile-page') ? profilePage() : null
    document.body.classList.contains('leaderboard-page') ? leaderboardPage() : null
    document.body.classList.contains('forget-page') ? forgetPage() : null
    document.body.classList.contains('reset-page') ? resetPage() : null
    document.body.classList.contains('about-page') ? window.location.hash&&jQuery('html, body').animate({ scrollTop: jQuery(window.location.hash).offset().top-jQuery('nav')[0].offsetHeight-5}, 1000): null
    // document.body.classList.contains('reset-page') ? console.log('Reset Page') : null
  const token=localStorage.getItem('token');
  const userImage=localStorage.getItem('userImage');
  if(userImage){
    jQuery('#userImage')[0].src=userImage;
    jQuery('#userImage1')[0].src=userImage;
  }
  const classList=document.body.classList;
  if(token){
    jQuery('#navbar .container .jc-btn.jc-btn__black ').fadeOut();
    jQuery('.nav-menu-overlay .jc-btn.jc-btn__black').fadeOut();
    jQuery('.third-section .jc-btn.jc-btn__black').fadeOut();
    jQuery('.section_two  .jc-btn.jc-btn__black').fadeOut();
    jQuery('#dropdownList1,#dropdownList').append(`<a class="dropdown-item pointer mt-0" href="userProfile.html">Profile</a>`)
    jQuery('#dropdownList1,#dropdownList').append(`<label for="image" class="mb-0 mt-0 dropdown-item pointer">Change Image</label>`)
    jQuery('#dropdownList1,#dropdownList').append(`<a class="dropdown-item pointer mt-0" id="logout">Log out</a>`)
  }
  else {
    jQuery('.userProfile-btn').fadeOut();
    jQuery('#dropdownMenuButton,#dropdownMenuButton1').fadeOut();
  }
    if(token){
      if (classList.contains('register-page') || classList.contains('sign-page') || classList.contains('login-page')||
        classList.contains('forget-page')||
        classList.contains('reset-page')) {
        return  window.location.href = "./game.html";

      }
      return ;
    }
      if (classList.contains('video-page') ||
        classList.contains('sign-userProfile-page') ||
        classList.contains('profile-page')||
        classList.contains('inner--level-page')||
        (classList.contains('game-page')&&!classList.contains('about-page'))

      ) {
        window.location.href = "./sign_up.html";

      }
}
runPages();





setTimeout(MACcheck, 1400); //delay for demonstration
function MACcheck() {
  var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  if (mac) {
        document.body.classList.add('adjustHrefForMac')
  }
}

jQuery('#logoutBtn,#logout').on('click',function () {
  localStorage.setItem('token','');
  localStorage.setItem('userImage','');
  window.location.href='./sign_up.html'
});
jQuery('#image').on('change',function (e) {
  $("#cropped-image--modal2").modal("show");
  const croppedImage = document.getElementById('croppedImage2');
  const cropBtn = document.getElementById('crop2');
  const view_image = document.getElementById('view_image2');
  const finalImage = document.getElementById('finalImage2');
  let newImage  = new Image();
  newImage.style.display='block';
  newImage.style.maxWidth='100%';
  let blobImag;
  let imageToProcess = e.target.files[0];
  newImage.src = URL.createObjectURL(imageToProcess);
  newImage.id = 'userImage2';
  view_image.appendChild(newImage)
  let deffaultUser = './imgs/user.png';
  finalImage.src = deffaultUser;
  let cropper=undefined,canvas=undefined,uploaded_image;
  let options = {
    aspectRatio: 1,
    viewMode:13,
    preview:croppedImage,
    zoomable:true,
    zoomOnTouch:true,
    crop(event){
      canvas = cropper.getCroppedCanvas({
        width:200,
        height:200,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });
      croppedImage.src = canvas.toDataURL('image/png')
      croppedImage.style.width = '200px';
      croppedImage.style.height = '200px';
    }
  }
  $('#cropped-image--modal2').on('shown.bs.modal',function(){
    cropper = new Cropper(newImage,options)
  }).on('hidden.bs.modal', function(){
    $( ".cropper-container" ).remove();
    $( "#view_image img" ).remove();
    finalImage.src = deffaultUser;
  });

  cropBtn.addEventListener('click', () =>{
    canvas = cropper.getCroppedCanvas({
      width:200,
      height:200
    })
    cropper.crop();
    try{
      canvas.toBlob(function(blob){
        finalImage.src = URL.createObjectURL(blob);
        blobImag=blob
      });
    }catch (e) {

    }
    console.log('blobImag',blobImag)
  })
  $('#saveImage2').on('click',function () {
    canvas = cropper.getCroppedCanvas({
      width:200,
      height:200
    })
    cropper.crop();
    try{
      canvas.toBlob(function(blob){
        finalImage.src = URL.createObjectURL(blob);
        blobImag=blob
      });
    }catch (e) {

    }
    console.log('blobImag',blobImag)
    const userId=JSON.parse(atob(localStorage.getItem('token').split('.')[1]))._id;
    console.log(userId)
    httpCall({
      url:`users/${userId}`,
      method:'patch',
      data:{
        profile:{
          "imgExt": "png",
          "contentType": "image/png"
        }
      },
      headers:{
        Authorization: localStorage.getItem("token")
      }
    }).then((res)=>{
      axios.put(res.data.profileSignedURL,blobImag,{
        headers: {
          'Content-Type': 'image/png',
        },
      }).then(()=>{
        localStorage.setItem('userImage',res.data.s3_original);
        $('#cropped-image--modal2').modal('hide');
        setTimeout(()=>{
          jQuery('#userImage')[0].src=res.data.s3_original;
          jQuery('#userImage1')[0].src=res.data.s3_original;
        },2000)
      })
    })
  })
});
jQuery('.jc-btn').hover(function (e) {
  const tes=this;
  setTimeout(()=>jQuery(tes).css('padding-top','2px'),100)
  setTimeout(()=>jQuery(tes).css('padding-top','0px'),300)
})