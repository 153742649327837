import {httpCall} from "../httpcall";
export const resetPage=()=>{
  const urlSearchParams=new URLSearchParams(window.location.search);
  const token=urlSearchParams.get('token');
  if(!token){
    setTimeout(()=>{
    jQuery.toast({
      text: "Please open the link from the email",
      loader:false,
      showHideTransition: 'fade', // fade, slide or plain
      allowToastClose: true, // Boolean value true or false

      position: 'top-left',
    })

    },500)
  }
  jQuery('#reset').on('click',function () {
    const password=jQuery('#password').val();
    const confirmPassword=jQuery('#confirm_password').val();
    if(password!==confirmPassword){
      jQuery.toast({
        text: "Password and confirm password not the same",
        loader:false,
        showHideTransition: 'fade', // fade, slide or plain
        allowToastClose: true, // Boolean value true or false

        position: 'top-left',
      })

    }
    else {
      httpCall({
        url: `auth/reset/${token}`,
        headers: {'Content-Type': 'application/json'},
        data: {
          password,
        },
        method: 'POST'
      }).then(()=>{
        window.location.href='/login.html'
      })
    }
  })
}