//import $ from 'jquery';
import videojs from 'video.js';
import {httpCall} from "../httpcall";
import axios from "axios";
import ConfettiGenerator from "confetti-js";
//import 'videojs-markers';


export const videoPage = () => {
  const supportedFormats= ['mp4', 'mov', 'wmv', 'flv', 'avi', 'webm'];
    const confettiSettings={
      "target": "confetti-video","max": "980",
      "size": "1","animate": true,
      "props": ["circle", "square", "triangle", "line"],
      "colors": [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126]
      ],
      "clock": "55","rotate": true,
      "width": "2560","height": "1271",
      "start_from_edge": false,"respawn": true
    };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    const confettiSettings2={
      "target": "confetti-video2","max": "1980",
      "size": "1","animate": true,
      "props": ["circle", "square", "triangle", "line"],
      "colors": [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126]
      ],
      "clock": "55","rotate": true,
      "width": "2560","height": "1271",
      "start_from_edge": false,"respawn": true
    };
    var confetti2 = new ConfettiGenerator(confettiSettings2);
    confetti2.render();
    //jQuery('.pop-up--normal').fadeIn();
    // initialize video.js
    var player = videojs('my-video');
    let file1,file2;
    let mainVideo={video:'./videos/intro.mp4',img:"./imgs/video-cover-2.jpg"}
    /*let alreadyUploaded=[{video:'./videos/intro.mp4',img:"./imgs/boom.png"},
        {video:'./videos/intro.mp4',img:"./imgs/video-cover-2.jpg"}];*/
    let alreadyUploaded=[];
  const fetchChallenge=()=>{
    httpCall({
      url:'game/challenges/'+window.location.href.split('challengeId=')[1],
      headers:{
        Authorization:localStorage.getItem('token')
      },method:'GET'}).then((res)=>{
      mainVideo={video:res.data.originalChallenge.URL,img:res.data.originalChallenge.s3_URLs._400_thumb_URL};
      alreadyUploaded=[res.data.challengeVidoes.filter(item=>item.videoType===0)[0],
        res.data.challengeVidoes.filter(item=>item.videoType===1)[0]].map((item)=>{
          return {
            video:item&&item.URL,
            img:item&&item.s3_URLs._400_thumb_URL
      }}).filter(i=>i);
        console.log('asdasdsad',res.data,alreadyUploaded);
      bindMainVideo();
    }).catch(()=>{
      setTimeout(()=>{
      jQuery.toast({
        text: "Check your connection",
        loader:false,
        showHideTransition: 'fade', // fade, slide or plain
        allowToastClose: true, // Boolean value true or false


        position: 'top-left',
      })

      },1000)
    })
  };
  fetchChallenge()
    const showUploadedVideos=()=>{
        jQuery('.timeline').addClass('d-none')
      const bar=jQuery('#videoOne .upload-inprogress .bar');
      bar.css('height',0);
      const bar2=jQuery('#videoTwo .upload-inprogress .bar');
      bar2.css('height',0);
        if(alreadyUploaded.length>0&&alreadyUploaded[0]&&alreadyUploaded[0].video){
        const firstVideo=jQuery('#videoOne img');
        firstVideo[0].src=alreadyUploaded[0].img;
            jQuery('#videoOne').removeAttr('for');
            jQuery('#videoOne').on('click',function () {
                player.src(alreadyUploaded[0].video);
                player.poster(alreadyUploaded[0].img);
            });
        jQuery('#videoOne').removeClass('upload-hide');
        jQuery('#videoOne').addClass('upload-complete overflow-visible');
        jQuery('#videoOne img').addClass('d-block');

        }
if(alreadyUploaded.length>1&&alreadyUploaded[1]&&alreadyUploaded[1].video){
    const secondVideo=jQuery('#videoTwo img');
    secondVideo[0].src=alreadyUploaded[1].img;
    jQuery('#videoTwo').on('click',function () {
        player.src(alreadyUploaded[1].video);
        player.poster(alreadyUploaded[1].img);
    });
    jQuery('#videoTwo').removeClass('upload-hide');
    jQuery('#videoTwo').removeAttr('for');
    jQuery('#videoTwo').addClass('upload-complete overflow-visible');
    jQuery('#videoTwo img').addClass('d-block');
}
if(alreadyUploaded.length>1&&alreadyUploaded[0]&&alreadyUploaded[0].video
  &&alreadyUploaded[1]&&alreadyUploaded[1].video){
        jQuery('#saveVideos').addClass('d-none')
}
    };

    const bindMainVideo=()=>{
        player.src(mainVideo.video);
        player.poster(mainVideo.img);
        jQuery('#originalVideo img')[0].src=mainVideo.img;
        jQuery('#originalVideo').on('click',function () {
            player.src(mainVideo.video);
            player.poster(mainVideo.img);
        })
        if(alreadyUploaded.length!==0){
            showUploadedVideos()
        }
    };
    const stopVideoError=(player,message)=>{
        player.pause();
        jQuery.toast({
            text: message||"You need to select video first",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
        })
    };
    /*player.on('play',function (e) {
        if(player.currentTime()>3.5&&!file1){
            stopVideoError(player);
        }
        if(player.currentTime()>3.5&&file1&&file1.size/1000/1000>50){
            stopVideoError(player,'max video size is 50MB');
        }
        if(player.currentTime()>7.5&&!file2){
            stopVideoError(player)
        }
        if(player.currentTime()>3.5&&file2&&file2.size/1000/1000>50){
            stopVideoError(player,'max video size is 50MB');
        }
    })*/


// upload-complete upload-progress upload-hide
    jQuery('#file1').on('change',function (event) {

        let file = event.target.files[0];
        file1=file;
        let blobURL = URL.createObjectURL(file);
        jQuery('#videoOne video')[0].src=blobURL;
        jQuery('#videoOne').removeClass('upload-hide');
        jQuery('#videoOne').addClass('upload-in-progress');
        if(file.size/1000/1000>50){
            stopVideoError(player,'max video size is 50MB')
        }
        const extension=file.name.split('.')[file.name.split('.').length-1];
        if(!supportedFormats.includes(extension.toLowerCase())){
          jQuery.toast({
            text: "This extension "+extension+" is not supported!",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
          });
        }
    });
    jQuery('#file2').on('change',function (event) {
        let file = event.target.files[0];
        file2=file;
        let blobURL = URL.createObjectURL(file);
        jQuery('#videoTwo video')[0].src=blobURL;
        jQuery('#videoTwo').removeClass('upload-hide');
        jQuery('#videoTwo').addClass('upload-in-progress');
        if(file.size/1000/1000>50){
            stopVideoError(player,'max video size is 50MB')
        }
      const extension=file.name.split('.')[file.name.split('.').length-1];
      if(!supportedFormats.includes(extension.toLowerCase())){
        jQuery.toast({
          text: "This extension "+extension+" is not supported!",
          loader:false,
          showHideTransition: 'fade', // fade, slide or plain
          allowToastClose: true, // Boolean value true or false


          position: 'top-left',
        });
      }
    })
    let uploading=false;
jQuery('#saveVideos').on('click',function () {
    if(!file1&&!file2){
        return jQuery.toast({
            text: "You need to select a video",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
        });

    }
  if(file1){
  const extension=file1.name.split('.')[file1.name.split('.').length-1];
  if(!supportedFormats.includes(extension.toLowerCase())){
    return jQuery.toast({
      text: "This extension "+extension+" is not supported!",
      loader:false,
      showHideTransition: 'fade', // fade, slide or plain
      allowToastClose: true, // Boolean value true or false


      position: 'top-left',
    });
  }
  }
    if(file2){

  const extension2=file2.name.split('.')[file2.name.split('.').length-1];
  if(!supportedFormats.includes(extension2.toLowerCase())){
    return jQuery.toast({
      text: "This extension "+extension2+" is not supported!",
      loader:false,
      showHideTransition: 'fade', // fade, slide or plain
      allowToastClose: true, // Boolean value true or false


      position: 'top-left',
    });
  }
    }
    if((file1&&file1.size/1000/1000>50)||(file2&&file2.size/1000/1000>50)){
      return jQuery.toast({
        text: "max video size is 50MB",
        loader:false,
        showHideTransition: 'fade', // fade, slide or plain
        allowToastClose: true, // Boolean value true or false


        position: 'top-left',
      });
    }
    let showConfetti;
    let showConfetti2;
    if(uploading) return;
    jQuery("#file1")[0].setAttribute('disabled','disabled');
    jQuery("#file2")[0].setAttribute('disabled','disabled');
    window.onbeforeunload=()=>{return 'Are you sure you want to leave?';}
    // upload files
    const promise=[];
    if(file1&&!file1.uploaded){
        console.log(file1);
        const bar=jQuery('#videoOne .upload-inprogress .bar');
        const percentage=jQuery('#videoOne .upload-inprogress .percentage');
        promise.push(httpCall({url:'game/videos',data:{
                name:file1.name,
                contentType:file1.type,
                mediaType:1,
            videoType:0,
            challengeID:window.location.href.split('challengeId=')[1],
            metadata:{
              vidExt:file1.name.split('.')[file1.name.split('.').length-1].toLowerCase(),
              contentType:file1.type
            }
            },headers:{
        Authorization:localStorage.getItem('token')
      }}).then((data)=>{
                file1.uploaded=true;
                if(!showConfetti){
                  showConfetti=data.data.weekComplete;
                }
                if(!showConfetti2){
                  showConfetti2=data.data.seasonComplete;
                }
            uploading=true;
            jQuery('#videoOne').removeClass('upload-in-progress');
            jQuery('#videoOne').addClass('upload-progress');
            return axios.put(data.data.signedURL, file1, {
                headers: {
                    'Content-Type': file1.type,
                },
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    percentage.text(percentCompleted+'%');
                    bar.css('height',percentCompleted+'%');
                },
            })/*.then(()=>{
                percentage.text('100%');
                bar.css('height','100%');
            })*/
        }));
    }
    if(file2&&!file2.uploaded){
        const bar=jQuery('#videoTwo .upload-inprogress .bar');
        const percentage=jQuery('#videoTwo .upload-inprogress .percentage');
        promise.push(httpCall({url:'game/videos',data:{
                name:file2.name,
                contentType:file2.type,
                mediaType:1,
            videoType:1,
            challengeID:window.location.href.split('challengeId=')[1],
            metadata:{
                  vidExt:file2.name.split('.')[file2.name.split('.').length-1].toLowerCase(),
              contentType:file2.type
                }
            },headers:{
            Authorization:localStorage.getItem('token')
          }}).then((data)=>{
            file2.uploaded=true;
            uploading=true;
          if(!showConfetti){
            showConfetti=data.data.weekComplete;
          }
          if(!showConfetti2){
            showConfetti2=data.data.seasonComplete;
          }
            jQuery('#videoTwo').removeClass('upload-in-progress');
            jQuery('#videoTwo').addClass('upload-progress');
            return axios.put(data.data.signedURL, file2, {
                headers: {
                    'Content-Type': file2.type,
                },
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    percentage.text(percentCompleted+'%');
                    bar.css('height',percentCompleted+'%');
                },
            })/*.then(()=>{
                percentage.text('100%');
                bar.css('height','100%');
            })*/
        }));
    }
    if(promise.length>0)
    Promise.all(promise).then(()=>{
        uploading=false;
        window.onbeforeunload=null;
        if(file1&&!file2){
            jQuery('#file2').removeAttr('disabled')
        }
        if(file2&&!file1){
            jQuery('#file1').removeAttr('disabled')
        }
        if(showConfetti&&!showConfetti2){
          jQuery('.pop-up--badge.v1').fadeIn();

          setTimeout(()=>{
            jQuery('.pop-up--badge.v1').fadeOut();
          },5000);
          setTimeout(()=>{
            fetchChallenge();
          },10000)
        }
        else if(showConfetti2){
          jQuery('.pop-up--badge.v2').fadeIn();

          setTimeout(()=>{
            jQuery('.pop-up--badge.v2').fadeOut();
          },5000);
          setTimeout(()=>{
            fetchChallenge();
          },10000)
        }
        else {
          jQuery.toast({
            text: "Your video uploaded successfully",
            loader:false,
            showHideTransition: 'fade', // fade, slide or plain
            allowToastClose: true, // Boolean value true or false


            position: 'top-left',
          });
        setTimeout(()=>{
          fetchChallenge();
        },7000)

        }

    })
})

























}